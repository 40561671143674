import "./styles.scss"

import React from "react"

import SingleSpecialist from "../SingleSpecialist"

const SpecialistsTails = ({ specialists }) => {
  return (
    <section className="specialists-tails">
      <div className="container">
        <div className="row">
          {specialists?.map(({ id, featuredImage, acfSpecialists, slug }) => {
            return (
              <div key={id} className="col-lg-4 col-sm-6 col-12">
                <SingleSpecialist
                  specialist={acfSpecialists}
                  img={featuredImage}
                  slug={slug}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SpecialistsTails
