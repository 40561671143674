import { graphql } from "gatsby"
import React, { useState } from "react"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { SearchBar, SpecialistsTails } from "page_components/specialists"

const Team = ({ data }) => {
  let filtered_specialists = data?.allWpSpecialist?.nodes
  let filtered_specializations = data?.allWpSpecialization?.nodes

  const [currentCategory, setCurrentCategory] = useState(null)
  const [currentLocation, setCurrentLocation] = useState(null)
  const [specName, setSpecName] = useState(null)

  const breadcrumbs_data = [
    {
      name: "Zespół",
      href: "/zespol/",
    },
  ]

  if (!!currentLocation) {
    filtered_specialists = filtered_specialists?.filter(
      location => location.acfSpecialists.location === currentLocation
    )

    const specialistSpecializationIds = filtered_specialists.flatMap(
      specialist =>
        specialist.specializations.nodes.map(
          specialization => specialization.id
        )
    )
    filtered_specializations = filtered_specializations.filter(specialization =>
      specialistSpecializationIds.includes(specialization.id)
    )
  }

  if (!!currentCategory) {
    filtered_specialists = filtered_specialists?.filter(specialist =>
      specialist.specializations.nodes.some(e => e.name === currentCategory)
    )
  }

  if (specName) {
    filtered_specialists = filtered_specialists?.filter(
      specialist => specialist.acfSpecialists.name === specName
    )
  }

  return (
    <Layout
      seo={{
        title: "Zespół",
        description:
          "Specjalizujemy się wyłącznie w podologii i stale poszerzamy wiedzę w tej dziedzinie. Mamy zespół z najlepszymi kwalifikacjami",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Zespół" />
      <SearchBar
        filtered_specialists={filtered_specialists}
        specializations={filtered_specializations}
        locations={data?.allWpLocation?.nodes}
        currentLocation={currentLocation}
        setCurrentLocation={setCurrentLocation}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        specName={specName}
        setSpecName={setSpecName}
      />
      <SpecialistsTails specialists={filtered_specialists} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpSpecialist(sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        specializations {
          nodes {
            id
            name
          }
        }
        acfSpecialists {
          booking
          name
          location
          shortDesc
          specDesc
          specTitle
        }
      }
    }
    allWpSpecialization {
      nodes {
        id
        name
      }
    }
    allWpLocation {
      nodes {
        id
        name
        slug
      }
    }
  }
`

export default Team
