import "./styles.scss"

import React from "react"

import SpecialistsSelect from "../SpecialistsSelect"
import CategorySelect from "../CategorySelect"
import LocationSelect from "../LocationSelect"

const SearchBar = ({
  filtered_specialists,
  specializations,
  locations,
  currentLocation,
  setCurrentLocation,
  currentCategory,
  setCurrentCategory,
  specName,
  setSpecName,
}) => {
  return (
    <section className="specialist-search">
      <div className="container">
        <div className="specialist-search__block">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="specialist-search__categories">
                <p className="specialist-search__info">
                  Pokaż według lokalizacji
                </p>
                <LocationSelect
                  locations={locations}
                  currentLocation={currentLocation}
                  setCurrentLocation={setCurrentLocation}
                />
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="specialist-search__categories">
                <p className="specialist-search__info">
                  Pokaż według specjalizacji
                </p>
                <CategorySelect
                  disabled={!!!currentLocation}
                  specializations={specializations}
                  currentCategory={currentCategory}
                  setCurrentCategory={setCurrentCategory}
                />
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="specialist-search__select--wrapper">
                <p className="specialist-search__info">
                  Znajdź swojego specjalistę
                </p>
                <div className="specialist-search__select">
                  <SpecialistsSelect
                    filtered_specialists={filtered_specialists}
                    specName={specName}
                    setSpecName={setSpecName}
                    disabled={!!!currentLocation}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SearchBar
