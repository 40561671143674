import "./styles.scss"

import React from "react"
import Select from "react-select"

import Xmark from "components/Icons/Xmark"

const LocationSelect = ({ locations, currentLocation, setCurrentLocation }) => {
  const getSelectOptions = locations => {
    const options = []
    locations?.forEach(location => {
      options.push({
        value: location.name,
        label: location.name,
      })
    })
    return options
  }

  const options = getSelectOptions(locations)

  const handleChange = value => {
    setCurrentLocation(value)
  }

  const selectStyles = {
    input: provided => ({
      ...provided,
      color: "#000",
    }),
    container: provided => ({
      ...provided,
      width: "100%",
      background: "#FAFAFA",
      backgroundColor: "#FAFAFA",
      outline: "none",
      border: "1px solid #FAFAFA",
      borderRadius: "3px",
      padding: "1px 5px",
      fontSize: "18px",
      fontWeight: 400,
      color: "#BABABA",
    }),
    control: provided => ({
      ...provided,
      background: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff",
      right: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      color: "#000",
      backgroundColor: state.isSelected ? "#f4f4f4" : "transparent",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#56ce32",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorContainer: provided => ({
      ...provided,
      color: "#000",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "18px",
      fontWeight: 400,
      color: "#BABABA",
    }),
    singleValue: provided => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: "block",
      color: "#000",
    }),
  }

  return (
    <div className="specialists-select">
      <Select
        value={currentLocation}
        placeholder="Wybierz lokalizację"
        options={options}
        styles={selectStyles}
        onChange={e => handleChange(e.value)}
      />
      {currentLocation && (
        <div className="specialists-select__choice">
          <span>{currentLocation}</span>
          <button type="button" onClick={() => setCurrentLocation(null)}>
            <Xmark />
          </button>
        </div>
      )}
    </div>
  )
}

export default LocationSelect
