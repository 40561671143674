import "./styles.scss"

import React, { useState } from "react"
import { navigate } from "gatsby"

import Button from "components/Button"
import BookingModal from "../BookingModal"
import trimExcerpt from "utils/trimExcerpt"

const SingleSpecialist = ({ specialist, img, slug }) => {
  const [bookingModal, setBookingModal] = useState(false)

  const { name, booking, shortDesc, specTitle, specDesc, location } = specialist

  const bookingModalHandler = () => {
    if (location === "Warszawa") {
      const element = document.getElementsByClassName(
        "booksy-widget-button"
      )?.[0]
      element.click()
    } else {
      setBookingModal(true)
    }
  }

  return (
    <div className="single-specialist">
      <div className="single-specialist__img">
        <img src={img?.node?.sourceUrl} alt={img?.node?.altText} />
      </div>
      <div className="single-specialist__content">
        <p className="single-specialist__name">{name}</p>
        <p className="single-specialist__spec">{specTitle}</p>
        {shortDesc && (
          <p className="single-specialist__desc">
            {trimExcerpt(shortDesc, 180)}
          </p>
        )}
        {booking && (
          <Button
            type="button"
            className="button-dark-green"
            onClick={bookingModalHandler}
          >
            Umów wizytę
          </Button>
        )}
        {specDesc && (
          <button
            type="button"
            className="single-specialist__open"
            onClick={() => navigate(`/zespol/${slug}/`)}
          >
            Dowiedz się więcej
          </button>
        )}
      </div>
      {bookingModal && (
        <BookingModal
          handleClose={() => setBookingModal(false)}
          name={name}
          spec={specTitle}
          img={img}
          booking={booking}
          setBookingModal={setBookingModal}
          slug={slug}
        />
      )}
    </div>
  )
}

export default SingleSpecialist
